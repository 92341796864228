import { render, staticRenderFns } from "./AdminLog.vue?vue&type=template&id=2b53663a&scoped=true&"
import script from "./AdminLog.vue?vue&type=script&lang=js&"
export * from "./AdminLog.vue?vue&type=script&lang=js&"
import style0 from "./AdminLog.vue?vue&type=style&index=0&id=2b53663a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b53663a",
  null
  
)

export default component.exports